<template>

  <div class="home" id="home"  >

        <section class="banner">
          <van-swipe class="swiper-carousel" lazy-render :autoplay="3000"  :show-indicators="true"  indicator-color="#fff"   @change="onChange">
            <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="openBanner(item,index)">
              <!-- @click="handleClick" -->
              <van-image fit="cover" class="lazy_img" :src="item.img" />
            </van-swipe-item>
          </van-swipe>

<!--          <ul class="indicators indicator">-->
<!--            <li v-for="(item, index) in bannerList" :class=" bannerActive == index ? 'active' : ''" :key="index" ></li>-->
<!--          </ul>-->
        </section>




    <div id="demo" class="demo">

      <div id="img-div" class="img-div">


        <img  id="demoImg" src="../../assets/map.png" class="demo-img" />

        <div  class="circles" id="circles">


          <img class="circleImg" :src="index == this.selectIndex ? imgType[3] :  imgType[storeList[index].mainVenue]" v-for="(item,index) in storeList" :key="index"
               :id="'refCircle'+index" @click="clickRefCircle(index)"
               :style="'height:'+ 32.0/scale +'px;'+'transform:'  + ' translateX(-50%)'  + ' translateY(-50%)'+ ';position:absolute;display:block;left: '+ item.percentageX   + '%' +';top: '+ item.percentageY + '%' +';'"/>




        </div>
      </div>
      <div class="bottom-btns">
<!--        v-show = "selectIndex > 0 &&  storeList[selectIndex].mainVenue == 2  ? false : true"-->
        <div   class="left-btn" @click="clockCard">
          <img src="../../assets/home/icon_scan.png" alt="">
          <span>打卡领福利</span>
        </div>

        <div v-show="show" class="right-btn"  @click="toGuide">
          <img src="../../assets/home/icon_guide.png" alt="">
          <span>导航</span>
        </div>
        </div>

      <div class="logo-img">
        <img src="../../assets/home/icon_home_logo.png" alt="">
      </div>

      <div class="bigger-btn" @click="toBigMap">
        <img src="../../assets/home/icon_bigger.png" alt="">
      </div>

    </div>




    <div class="pop-store-info" v-if="selectIndex >= 0" v-show="show" >
      <div id="refStoreInfo" class="store-info" :style="{ left:popLeft+'px',top:popTop+'px',transform:'translateY('+ (popIsTop ? '-100%' : '0' ) +')'+' translateX(-50%)'}">
        <p class="store-name">{{storeList[selectIndex].name}}</p>
        <p class="store-desc">{{storeList[selectIndex].desc}}</p>
        <div v-show="storeList[selectIndex].mainVenue != 2" @click="toStoreDetail" class="store-detail-btn">查看详情</div>
      </div>
    </div>





    <tabbar></tabbar>




  </div>
</template>
<script>
import Hammer from 'hammerjs'
import { setTimeout } from 'timers'
import {clearToast, showFailToast, showLoading} from "../../utils/util";
import {Dialog} from "vant";
export default {
  components: {},

  data () {
    return {

      show:false,
      localUrl:window.location,
      bannerActive:0,
      bannerList:[],
      storeList:[],
      config: {},
      demo: null,
      imgDiv:null,
      circles:null,
      refStoreInfo:null,
      id: null,
      mc: null,
      timer: false,
      translateX: 0,
      translateY: 0,
      scale: 1.2,
      firstTouch: true,
      relateX: 0,
      relateY: 0,
      oldX: 0,
      oldY: 0,
      oldScale: 1,
      //弹出窗口位置
      popLeft:0,
      popTop:0,
      popIsTop:true,
      popIsLeft:true,
      screenW:0,
      screenH:0,

      selectIndex:-1,

      storeKeyList:{},

      imgType:{

        0:require('../../assets/home/location_other.png'),
        1:require('../../assets/home/location_main.png'),
        2:require('../../assets/home/location_see.png'),
        3:require('../../assets/home/location_select.png'),

      },


    }
  },
  mounted () {

    this.$nextTick(() => {
      this.picInit()
    })

  },
  unmounted() {
    this.mc = null;
    this.id = null;
  },
  created() {
    this.loadData();


    //埋点上报
    eshimin.bytedanceApplogEvent({
      eventName: 'book_detail', // 例子
      eventParams: {'page_name': '首页', 'venue_name': ''} ,//例子
      onResponse:  (res)=> {
      console.log("bytedanceApplogEvent responseData:" + res);
    }
  })

  },
  methods: {
    toGuide() {

      if(this.show){
        //
        // let res = {longitude: 112.944090,latitude:28.163492}
        //
        //

        //判断是否是主会场
        let currentTime = new Date();//获取当前时间
        let startTime = Date.parse('2023-03-24 00:00:00'.replace(/-/g, "/"));
        let dateDiff = new Date(startTime).getTime() - currentTime.getTime();//时间差的毫秒数
        if(this.storeList[this.selectIndex].mainVenue == 1 &&  dateDiff > 0){

          showFailToast('主会场24日开放');

        }else{
          // 获取当前位置
          eshimin.getAddress(
            {

              onResponse: (res)=> {
                let  a = JSON.parse(res);
                // if(a.code === 200){


                window.location.href =  ('https://uri.amap.com/navigation?from='+ a.longitude +','+ a.latitude +',我的位置&to='+ this.storeList[this.selectIndex].longitude +','+this.storeList[this.selectIndex].latitude+','+this.storeList[this.selectIndex].name +'&mode=car&policy=1&src=mypage&coordinate=gaode&callnative=0')

                // }else{
                //   showFailToast(a.msg)
                // }
                // alert('longitude'+'===' + a.longitude)
                // alert('latitude'+'===' + a.latitude)

              }
            }

          )

        }



      }


    },
    clockCard() {





      //先获取定位,然后再扫码打卡
      let  that = this;

      eshimin.getBarcode( {
        onResponse:  (resCode)=> {
          let codeJson =  JSON.parse(resCode);
          //埋点上报
          eshimin.bytedanceApplogEvent({
            eventName: 'book_click_home', // 例子
            eventParams: {'venue_name': this.storeKeyList[codeJson.barcode].name} ,//例子
            onResponse:  (res)=> {
              console.log("bytedanceApplogEvent responseData:" + res);
            }
          })

          if(codeJson.barcode && codeJson.barcode.length > 0){
            eshimin.getAddress( {
              onResponse: (resAddress) => {
                resAddress = JSON.parse(resAddress);
                // if(resAddress.code === 200){
                  let params = {'code':codeJson.barcode,'longitude':resAddress.longitude,'latitude':resAddress.latitude}
                  showLoading('加载中')
                  that.$http.post("/wenwu-user/bookstore/clock",params).then(res => {
                    clearToast()
                    if(res.data.code == 200){
                      that.$router.push({name:'getDigitalSuccess',query:res.data.data})
                    }else if(res.data.code == 7008) {
                      Dialog.alert({
                        confirmButtonColor:'#333',
                        messageAlign:'left',
                        message: res.data.message,
                      }).then(() => {
                        // on close
                      });
                    } else{
                      showFailToast(res.data.message)
                    }

                  }).catch((err)=>{
                    showFailToast(err.message);
                  })
                // }else{
                //   showFailToast(resAddress.msg);
                // }


              }
            })
          }

        }
      });

    },

    toStoreDetail() {
      this.$router.push({name:'storeDetail',query:{id:this.storeList[this.selectIndex].id}});
    },
    openBanner(item,index) {

      //埋点上报
      this.$router.push({name:'videoDetail'});
      eshimin.bytedanceApplogEvent({
        eventName: 'book_click_banner', // 例子
        eventParams: {'banner_name': item.title, 'rank_num':index} ,//例子
        onResponse:  (res)=> {
          console.log("bytedanceApplogEvent responseData:" + res);
        }
      })


      // if(item.detailImg != null && item.detailImg != ''){
      //   this.$router.push({name:'openBanner',query:{image:item.detailImg}});
      // }else{


        // window.location.href = item.url;
      // }

    },
    toBigMap() {
      this.$router.push('/bigMap');
    },
    touchStart () {
      if(this.show){
        this.show = false;
        this.selectIndex = -1;
      }
    },
    loadData() {

      this.$http.get("/wenwu-custom-services/bookstore/index").then(res => {
        this.bannerList = res.data.data.bannerList;
        this.storeList = res.data.data.bookstoreList
        this.storeList.forEach((item)=>{
          this.storeKeyList[item.code] = item;
        })
      })


    },
    clickRefCircle(index) {
      this.show = true
      this.selectIndex = index;

      let  refName = 'refCircle' + index
      this.$nextTick(() => {

        let rect =  document.getElementById(refName).getBoundingClientRect();
        // let rect = this.$refs['refCircle1'].getBoundingClientRect();
        if(rect.top >= (this.screenH/2.0)){
          this.popIsTop = true;
          this.popTop = rect.top;
        }else{
          this.popIsTop = false;
          this.popTop = rect.top + rect.height;
        }

        if((rect.left + rect.width/2.0)  >= (this.screenW/2.0)){
          this.popIsLeft = false;
          this.popLeft = rect.left;

        }else{
          this.popIsLeft = true;
          this.popLeft = rect.left + rect.width/2.0;
        }

        let refStoreInfoRect =  document.getElementById('refStoreInfo').getBoundingClientRect();

        if((refStoreInfoRect.width + this.popLeft) > this.screenW) {
          this.popLeft =  this.screenW -  refStoreInfoRect.width/2.0;
        }


        if((this.popLeft -  refStoreInfoRect.width/2.0) < 0) {
          this.popLeft =  refStoreInfoRect.width/2.0 ;
        }

      })




    },
     onChange(index) {
      this.bannerActive = index;
    },
    picInit () {

      let screenInfo = document.getElementById('home')

      this.screenW = screenInfo.clientWidth
      this.screenH = screenInfo.clientHeight
      this.demo = document.getElementById('demo')
      this.id = document.getElementById('demoImg')
      this.circles = document.getElementById('circles')
      this.imgDiv =  document.getElementById('img-div')
      this.refStoreInfo =  document.getElementById('refStoreInfo')
      this.mc = new Hammer(this.imgDiv)
      this.id.addEventListener('load',()=>{
        this.relateX = (this.demo.clientWidth - this.id.offsetWidth) * 0.88
         this.relateY = (this.demo.clientHeight - this.id.offsetHeight) * 0.3
        this.setPosition()
      })




      this.mc.add(new Hammer.Pan({
        direction: Hammer.DIRECTION_ALL,
        threshold: 0,
        pointers: 0
      }))
      this.mc.add(new Hammer.Pinch({
        threshold: 0
      })).recognizeWith(this.mc.get('pan'))

      this.mc.on('hammer.input', this.isFinal)
      this.mc.on('panstart panmove', this.onPan)
      this.mc.on('pinchstart pinchmove', this.onPinch)

      // this.setPosition()
    },
    isFinal (ev) {

      if (ev.isFinal) {
        this.oldX = this.translateX
        this.oldY = this.translateY
        this.oldScale = this.scale
      }
    },
    // 初始化图片位置及缩放
    setPosition () {
      this.selfPosition({
        translateX: this.relateX,
        translateY: this.relateY,
        scale: this.scale
      })
    },
    // 单点触发 - 拖拉
    onPan (ev) {
      // console.log(this.firstTouch)
      if (this.firstTouch) {
        this.oldX = this.relateX
        this.oldY = this.relateY
      }
      // console.log(this.oldX)
      // console.log(this.oldY)
      this.translateX = this.oldX + ev.deltaX
      this.translateY = this.oldY + ev.deltaY

      //设置屏幕

      if(this.translateX > this.id.offsetWidth * (this.scale-1) *0.5) {
        this.translateX =  this.id.offsetWidth * (this.scale-1) *0.5
      }
      if(this.translateX < -(this.id.offsetWidth * (this.scale-1) *0.5 + this.id.offsetWidth) + this.demo.clientWidth){
        this.translateX =  -(this.id.offsetWidth * (this.scale-1) *0.5 + this.id.offsetWidth) + this.demo.clientWidth
      }
      if(this.translateY > this.id.offsetHeight * (this.scale-1) *0.5 ){
        this.translateY =  this.id.offsetHeight * (this.scale-1) *0.5
      }

      if(this.translateY < -(this.id.offsetHeight * (this.scale-1) *0.5 + this.id.offsetHeight) + this.demo.clientHeight){
        this.translateY =  -(this.id.offsetHeight * (this.scale-1) *0.5 + this.id.offsetHeight) + this.demo.clientHeight
      }



      const position = {
        translateX: this.translateX,
        translateY: this.translateY,
        scale: this.scale
      }



      this.selfPosition(position)
      this.firstTouch = false
    },
    // 多点触发 - 缩放
    onPinch (ev) {
      this.scale = this.oldScale * ev.scale
      if(this.scale <= 1){
        this.scale = 1;
      }
      if(this.scale >= 2.0){
        this.scale = 2.0;
      }



      this.selfPosition({
        translateX: this.translateX,
        translateY: this.translateY,
        scale: this.scale
      })
      // this.selfPosition(this.position)
    },
    selfPosition (pos) {
      return this.picAnimate()(() => this.tempPos(pos))
    },
    tempPos (pos) {
      // console.log('translateX-----'+ pos.translateX)
      // console.log('translateY-----'+ pos.translateY)



      this.show = false

      let style = [
        `translate3d(${pos.translateX}px, ${pos.translateY}px, 0)`,
        `scale(${pos.scale}, ${pos.scale})`
        // `scale(${pos.scale > 1.2 ? 1.2 : pos.scale}, ${pos.scale > 1.2 ? 1.2 : pos.scale})`
      ]
      style = style.join(' ')
      this.id.style.transform = style
      this.circles.style.transform = style
    },
    picAnimate () {
      return window[Hammer.prefixed(window, 'requestAnimationFrame')] || function (callback) {
        setTimeout(callback, 1000 / 60)
      }
    }
  }
}
</script>
<style scoped lang="scss" src="./index.scss">

</style>

